import { useEffect } from 'react'

import useBootstrapQuery, { BootstrapQuery } from '@/queries/useBootstrapQuery'
import routes, { Route } from '@/routes'
import { useRouter } from 'next/router'

const getRouteGivenBootstrap = (currentPage: Route, bootstrapData: BootstrapQuery) => {
  const { data, error } = bootstrapData

  if (error && error.response?.status == 401 && currentPage != routes.login) {
    return routes.login
  }

  if (!data) return

  if (!data.isAdvisor && currentPage !== routes.index) {
    return routes.index
  }

  if (data.isAdvisor && currentPage == routes.index) {
    return routes.adviserSearch
  }

  if (data.isAdvisor) {
    // if adviser redirect to current page
    return
  }

  return routes.getStarted
}

export const useBootstrapRedirect = ({ currentPage }: { currentPage: Route }) => {
  const bootstrapQuery = useBootstrapQuery({})
  const router = useRouter()

  const redirect = getRouteGivenBootstrap(currentPage, bootstrapQuery)

  useEffect(() => {
    if (redirect) {
      router.replace(redirect)
    }
  }, [redirect, router])

  return {
    ...bootstrapQuery,
    isLoading: bootstrapQuery.isLoading || redirect,
    isRedirect: redirect,
  }
}
