import { useBootstrapRedirect } from '@/hooks/useBootstrapRedirect'
import LoadingPage from '@/components/LoadingPage'
import routes from '@/routes'

const IndexPage = () => {
  const { isLoading } = useBootstrapRedirect({ currentPage: routes.index })
  if (isLoading) return <LoadingPage />
}

export default IndexPage
