import { Spinner, VStack } from '@packages/ui-components'

import PageContainer from '@/components/PageContainer'
import PageLayout from '@/components/PageLayout'

const LoadingPage = () => (
  <PageContainer title="Welcome">
    <PageLayout>
      <VStack alignItems="center">
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        Loading
      </VStack>
    </PageLayout>
  </PageContainer>
)

export default LoadingPage
