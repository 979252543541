import { HttpError } from '@/utils/httpError'
import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import { Adviser } from '@dd-common/types'

type ResultValue = {
  success: boolean
  isAdvisor: boolean
  adviser: Adviser
}

export type BootstrapQueryResult = ResultValue

const handleResult = async (response: Response): Promise<ResultValue> => {
  if (!response.ok) {
    throw new HttpError(response)
  }

  const result = await response.json()
  if (!result.success) {
    throw new HttpError(response, 'Received invalid data from server')
  }

  return result
}

type BootstrapQueryParams = {
  opts?: UseQueryOptions<ResultValue, HttpError>
}

const useBootstrapQuery = ({ opts }: BootstrapQueryParams) => {
  const url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/bootstrap`

  const bootstrapQuery = useQuery(
    'bootstrapQuery' as QueryKey,
    () =>
      fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {},
      }).then(handleResult),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: 2,
      ...opts,
    }
  )

  return bootstrapQuery
}

export type BootstrapQuery = ReturnType<typeof useBootstrapQuery>

export default useBootstrapQuery
